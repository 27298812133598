import React from 'react';

const Features = () => {
  
  return (
    <section className='why_us'>
      <h1>Manage Your Property with ease</h1>
      <div className='Services_Flex'>
        <div className='Services'>
          <div className='benefits'>
            
            <div className='details'>
              <i className="fa-solid fa-percent fa-2xl"></i>
              <h3 >Increase Profit</h3>
              <span>Optimize cashflow and expenses</span>
            </div>
            <div className='details'>
              <i className="fa-solid fa-calculator fa-2xl"></i>
              <h3 >Lower Risk</h3>
              <span>Reduce the chances of a bad investment</span>
            </div>
            <div className='details'>
              <i className="fa-regular fa-money-bill-1 fa-2xl"></i>
              <h3>Streamlined Process</h3>
              <span>Simplify property analysis</span>
            </div>
            <div className='details'>
              <i className="fa-solid fa-coins fa-2xl"></i>
              <h3 >Maximize Returns</h3>
              <span>Make smarter investment decisions</span>
            </div>
            <div className='details'>
              <i className="fa-solid fa-file-invoice-dollar fa-2xl"></i>
              <h3 >Saves Time</h3>
              <span>Quickly assess property investments in minutes</span>
            </div>
            <div className='details'>
              <i className="fa-solid fa-money-bill-trend-up fa-2xl"></i>
              <h3 >Beginner Friendly</h3>
              <span>No real estate expertise required</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
