import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Navigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../Redux/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
function Login() {
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.auth);

  const onChange = (e) =>
    setLoginData({ ...loginData, [e.target.name]: e.target.value });


  const onSubmit = async (e) => {
    e.preventDefault();
    setLoadingStates(true);
    dispatch(login({ ...loginData }, setLoadingStates, setErrors));

  }

  useEffect(() => {
    setLoadingStates(loading);
  }, [loading]);


  if (isAuthenticated) {
    toast("You have been loggedIn successfully.");
    return <Navigate replace to="/" />;
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="row align-items-center justify-content-center login-page">
      <div className="col-xl-6 col-lg-6 col-sm-12 col-12 fxt-bg-color">
        <div className="login-wrapper">
          <div className="fxt-header">
            <p>
              Login to Rental Property Calculator - to retrieve/save your
              calculations.
            </p>
          </div>
          <div className="fxt-form">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-1">
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    required
                    autoComplete="email"
                    value={loginData.email}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-2">
                  <input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    required
                    autoComplete="password"
                    value={loginData.password}
                    onChange={(e) => onChange(e)}
                  />
                  <i
                    onClick={togglePasswordVisibility}
                    className={`fa fa-fw ${showPassword ? "fa-eye-slash" : "fa-eye"
                      } toggle-password field-icon`}
                  ></i>
                </div>
              </div>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-3">
                  <div className="fxt-checkbox-area">
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-3">
                  <div className="fxt-checkbox-area">
                    <Link to="/forgetpassword" className="switcher-text">
                      Forget Password?
                    </Link>
                  </div>
                  <div className="fxt-checkbox-area">
                    <div className="checkbox">
                      <label htmlFor="checkbox1" className="p-0">
                        Don't have an account?
                      </label>
                    </div>
                    <Link to="/signup" className="switcher-text">
                      SignUp
                    </Link>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-4">
                  <button type="submit" className="fxt-btn-fill">
                  {loadingStates ? (
                      <ThreeDots
                        color="white"
                        height={10}
                        width={35}
                        radius={100}
                        ariaLabel="loading"
                        wrapperStyle={{ marginLeft: '45%' }}
                      />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </div>
            </form>
            <div className="error-message">
            {errors.email && <div className="error-message">{errors.email}</div>}
            {errors.password && <div className="error-message">{errors.password}</div>}
            {errors.detail && <p>{errors.detail}</p>}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
