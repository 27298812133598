import React,{useEffect, useState} from "react";
import "react-toastify/dist/ReactToastify.css";

function Tutorial() {
  const tutorialStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };

  const iframeContainerStyles = {
    position: "relative",
    padding: "15%" ,
    marginBottom : "5%" ,
    width: "80%" 
  };

  const iframeStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  const contentContainerStyles = {
    textAlign: "center",
    marginTop: "20px",
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div style={tutorialStyles}>
      <div style={contentContainerStyles}>
        <h1 style={{ fontSize: "24px" }}>
          Tutorial For Rental Property Calculator
        </h1>
        <p style={{ fontSize: "16px" }}>
          Discover the ultimate guide to understanding calculator functionality.
          Unique differentiators of our software and explore the incredible
          array of features that set us apart.
        </p>
      </div>
      {loading ? (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
      <div style={iframeContainerStyles}>
        <iframe
          title="Tutorial Video"
          src="https://www.youtube.com/embed/uLPc0m92qJ4?si=IwmHCyiZeG_hEt8w"
          allowFullScreen
          style={iframeStyles}
        ></iframe>
      </div>
      )}
    </div>
  );
}

export default Tutorial;
