import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import msgReducer from "./message.reducer";
import loadingReducer from "./loading.reducer";
import ProfileReducer from "./profile.reducer";
import CalculatorReducer from "./calculator.reducer";
import LogsReducer from "./logs.reducer";
import UserReducer from "./Users.reducer"

const rootReducer = combineReducers({
  Logs: LogsReducer ,
  Users : UserReducer,
  auth: authReducer,
  message: msgReducer,
  profile: ProfileReducer,
  loading: loadingReducer,
  calculator: CalculatorReducer,
});

export default rootReducer;
