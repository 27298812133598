import React, { useState, useEffect } from 'react';
import Img from "../../../assets/images/graph.png"

let About = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return(
      <section >
        <div className={`about_container ${scrolled ? 'scroll-up' : ''}`}>

        <div className="about__section">
        <div className="about__content">
        <span >Why Us</span>
        <h1>We simplify the complexities of rental property management</h1>
         <p>From text-based calculations to accurate property valuations, we cover it all</p>
         </div>
         <div className="about__data">
            <div>
            <p> <i className="fa-solid fa-check fa-lg"></i> Easy Cashflow Analysis</p>
            </div>
            <div>  
            <p> <i className="fa-solid fa-check fa-lg"></i> Effortless Expense Tracking</p>
            </div>
            <div>  
            <p> <i className="fa-solid fa-check fa-lg"></i> Effecient ROI Projection</p> 
            </div>                   
          </div> 
          </div> 

            <div className="about__img">
            <img src={Img} alt="About_Us" />
            </div>

            </div>  

             <div>
    </div>     
      </section> 
    )
}

export default About