import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../Redux/actions/auth.actions";
import { ThreeDots } from "react-loader-spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Form.css"

function SignUp() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const loading = useSelector((state) => state.auth.loading);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      last_name: "",
      first_name: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_]).{8,}$/,
          "Password must be at least 8 characters with 1 uppercase, 1 lowercase, and 1 symbol (🔒)"
        )
        .required("Required"),
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setLoadingStates(true);
      dispatch(
        register(
          {
            ...values,
            password: values.password.trim(),
          },
          setLoadingStates,
        )
      );
    },
  });


  useEffect(() => {
    setLoadingStates(loading);
  }, [loading]);



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  if (isAuthenticated) {
    toast.success("Registered Successfully")
    return <Navigate replace to="/" />;
  }
  return (
    <div className="row align-items-center justify-content-center login-page">
      <div className="col-xl-6 col-lg-6 col-sm-12 col-12 fxt-bg-color">
        <div className="login-wrapper">
          <div className="fxt-header">
            <p>Signup to Rental Property Calculator - to retrieve/save your calculations</p>
          </div>
          <div className="fxt-form">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-1">
                  <input
                    type="text"
                    id="first_name"
                    className="form-control"
                    name="first_name"
                    placeholder="First Name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <p className="error-message">{formik.errors.first_name}</p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-1">
                  <input
                    type="text"
                    id="last_name"
                    className="form-control"
                    name="last_name"
                    placeholder="Last Name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <p className="error-message">{formik.errors.last_name}</p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-1">
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    required="required"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="error-message">{formik.errors.email}</p>
                  )}
                </div>
              </div>
              <div className={`form-group ${formik.errors.password ? "has-error" : ""}`}>
                <div className="fxt-transformY-50 fxt-transition-delay-2">
                  <div className="password-input-wrapper">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${formik.errors.password ? "input-error" : ""}`}
                      name="password"
                      placeholder="********"
                      required="required"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <i
                      onClick={togglePasswordVisibility}
                      className={`fa fa-fw ${showPassword ? "fa-eye" : "fa-eye-slash"
                        } toggle-password field-icon`}
                    ></i>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <p className="error-message">{formik.errors.password}</p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-3">
                  <div className="fxt-checkbox-area">
                    <div className="checkbox">
                      <label htmlFor="checkbox1" className="p-0">
                        Already have an account?
                      </label>
                    </div>
                    <Link to="/login" className="switcher-text">
                      Login
                    </Link>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="fxt-transformY-50 fxt-transition-delay-4">
                  <button type="submit" className="fxt-btn-fill">
                    {loadingStates ? (
                      <ThreeDots
                        color="white"
                        height={10}
                        width={35}
                        radius={100}
                        ariaLabel="loading"
                        wrapperStyle={{ marginLeft: '45%' }}
                      />

                    ) : (
                      ""
                    )}
                    Sign up
                  </button>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
