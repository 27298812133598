import React, { Suspense, lazy } from "react";
import Loader from "../components/Loader";

const AdminAccount = lazy(() => import("../components/AdminAccount/index"));

const Admin = () => {
  return (
    <Suspense
    fallback={
      <div className="loader-wrapper">
        <Loader />
      </div>
    }
  >
    <AdminAccount/>
  </Suspense>
  )
}

export default Admin