import React from 'react';
import reviews from "../../../assets/images/review stars.png";
import google from "../../../assets/images/google.png"
import yelp from "../../../assets/images/yelp.png"
import Cutomer1 from "../../../assets/images/Frank Kiln.png"
import Cutomer2 from "../../../assets/images/Linda Anand.png";
import Cutomer3 from "../../../assets/images/David Gueta.png";

const HappyCustomers = () => {
    return (
        <section className="Customers_Section">
            <div className="text-center"><h1>Testimonials!</h1></div>
            <div className="reviews_container">
                <div className="card review_card">
                    <img className="customer_image" src={Cutomer1} alt="Customer 1" />
                    <div className="card-body">
                        <h3 className="card-title">Frank Kiln</h3>
                        <p className="card-text">Discovering this rental property calculation website was a game-changer for me! Finally, a platform that seamlessly combines Cashflow Analysis, Expense Tracking, and ROI Projection in one place</p>
                        <img className="reviews_img" src={reviews} alt="5 star review"/>
                        <img className="search_engine" src={yelp} alt="yelp"/>
                    </div>
                </div>
                <div className="card review_card">
                    <img className="customer_image" src={Cutomer2} alt="Customer 2" />
                    <div className="card-body">
                        <h3 className="card-title">Linda Anand</h3>
                        <p className="card-text">Rental property calculator is like having a financial wizard in my pocket, making property investment decisions a breeze.Thank you for making my investment journey smarter and more profitable!</p>
                        <img className="reviews_img" src={reviews} alt="5 star review"/>
                        <img className="search_engine" src={yelp} alt="yelp"/>
                    </div>
                </div>
                <div className="card review_card">
                    <img className="customer_image" src={Cutomer3} alt="Customer 3" />
                    <div className="card-body">
                        <h3 className="card-title">David Gueta</h3>
                        <p className="card-text">Rental Property Calculator helped me track my investment easily.The user-friendly interface and detailed insights have transformed the way I approach real estate. Thanks!</p>
                        <img className="reviews_img" src={reviews} alt="5 star review"/>
                        <img className="search_engine" src={google} alt="google"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HappyCustomers;
