import axios from "../../utils/axios";
import { getTokenIncludedConfig } from "./common";
import {
  RESET_FAIL,
  RESET_SUCCESS,
  USER_CALCULATIONS_FAIL,
  USER_CALCULATIONS_SUCCESS,
} from "../constants";
import { toast } from "react-toastify";

export const getUserCalculations = () => async (dispatch) => {
  try {
    const res = await axios.get("users/calculations", getTokenIncludedConfig());
    dispatch({
      type: USER_CALCULATIONS_SUCCESS,
      payload: res.data.results,
    });
  } catch (error) {
    dispatch({
      type: USER_CALCULATIONS_FAIL,
    });
  }
};

export const change_password =
  (data, setLoading, setErrors) => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await axios.post(
        "/users/change_password",
        body,
        getTokenIncludedConfig()
      );
      dispatch({
        type: RESET_SUCCESS,
        payload: res.data,
      });
      toast.success("Password updated successfully.");
      setLoading(false);
    } catch (error) {
      setErrors("system respond is slow due to network please try again");
      setLoading(false);
      dispatch({
        error: error.response,
        type: RESET_FAIL,
      });
    }
  };
