import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "black",
    color: "white",
    cursor: "pointer", 
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
    marginBottom: "30px",
  },
});

export default function GenericTable({ columns, data }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [defaultSortedColumn, setDefaultSortedColumn] = useState(columns[1]); 
  const [defaultSortOrder, setDefaultSortOrder] = useState('asc');

  const rowsPerPage = 5;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleSort = (column) => {
    if (column === 'Property Name' || column === 'Date') {
      if (sortedColumn === column) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setSortedColumn(column);
        setSortOrder('asc');
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  const sortedData = data.slice().sort((a, b) => {
    if (sortedColumn || defaultSortedColumn) {
      const order = sortedColumn ? (sortOrder === 'asc' ? 1 : -1) : (defaultSortOrder === 'asc' ? 1 : -1);
      const targetColumn = sortedColumn || defaultSortedColumn;
      return a[targetColumn] > b[targetColumn] ? order : -order;
    }
    return 0;
  });

  const displayData = sortedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <TableContainer component={Paper} style={{ boxShadow: "none" }}>
          <Table className={classes.table} aria-label="customized table">
          <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <StyledTableCell key={index} onClick={() => handleSort(column)}>
                     {column}
                    {(sortedColumn === column || defaultSortedColumn === column) && (
                      <span className={classes.arrow}>
                        {sortedColumn === column ? (
                          sortOrder === "asc" ? <i className="ms-3 fa-solid fa-caret-down fa-2xl"></i> : <i className="ms-3  fa-solid fa-caret-up fa-2xl"></i>
                        ) : (
                          defaultSortOrder === "asc" ? <i className="ms-3  fa-solid fa-caret-down fa-2xl"></i> : <i className="ms-3  fa-solid fa-caret-up fa-2xl"></i>
                        )}
                      </span>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayData.map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  {columns.map((column, columnIndex) => (
                    <StyledTableCell key={columnIndex}>
                      {row[column]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className={classes.pagination}>
        <button
          className="btn btn-dark"
          onClick={() => handleChangePage(page - 1)}
          disabled={page === 0}
        >
          Previous
        </button>
        <span style={{ margin: "0 10px" }}>
          Page {page + 1} of {totalPages}
        </span>
        <button
          className="btn btn-dark"
          onClick={() => handleChangePage(page + 1)}
          disabled={(page + 1) * rowsPerPage >= sortedData.length}
        >
          Next
        </button>
      </div>
    </div>
  );
}
