import React from "react";
import hero from "../../assets/images/home.png";
import "./Home.css";
import CTA from "./child/CTA";
import About from "./child/About";

import Testimonal from "./child/Testimonials";
import { Link } from "react-router-dom";
import Features from "./child/Features";
import Typewriter from "typewriter-effect";

const Home = () => {
  return (
    <>
      <section className="Background">
        <div className="Home_Container">
          <div className="flex_item">
            <p>Unlock The Power Of Real Estate Investing With</p>
            <span
              style={{ color: "#87ceeb", fontSize: "2.5vw", fontWeight: "700" }}
            >
              Rental Property Calculator!
            </span>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString(
                    "Make Informed Investment Decisions with Confidence"
                  )
                  .pauseFor(1000)
                  .deleteAll()
                  .typeString(
                    "Make Informed Investment Decisions with Confidence"
                  )
                  .start();
              }}
            />
            <div>
              <Link to="/calculator">
                <button
                  type="submit"
                  className="btn  rounded-pill mt-3 Top_button"
                  style={{ backgroundColor: "#87ceeb", color: "white" }}
                >
                  Explore More
                </button>
              </Link>
            </div>
          </div>
          <div className="flex_item">
            <img className="top_img" src={hero} alt="hero top" />
          </div>
        </div>
      </section>
      <About />
      <Features />
      <div className="Video_Title">
        <h1>What we have to offer</h1>
        <span className="Video_SubTitle">Watch a demo</span>
      </div>
      <div style={{ marginLeft: "10%", position: "relative" }}>
        <iframe
          width="90%"
          height="450"
          src="https://www.youtube.com/embed/Ab7ZbNwO71Q"
          title="Rental Property Calculator"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <CTA />
      <Testimonal />
    </>
  );
};

export default Home;
