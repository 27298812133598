export function getTokenIncludedConfig() {
  const token = localStorage.getItem("access");
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }, 
  };
}
