import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/images/logo.png";
const styles = StyleSheet.create({
  container: {
    padding: 20,
  },

  userValueSection: {
    margin: "3 10",
  },
  image: {
    width: "15vw",
    height: "5vw",
    marginBottom: "20px",
  },
  topHeader: {
    backgroundColor: "lightgrey",
    fontWeight: 400,
    fontSize: "15px",
    color: "black",
    textAlign: "center",
    margin: "6px",
  },
  title: {
    backgroundColor: "#87ceeb",
    fontWeight: 900,
    fontSize: "20px",
    color: "black",
    textAlign: "center",
  },
  ValueSection: {
    margin: "5 20",
  },
  contentWrapper: {
    padding: 10,
    marginTop: 5,
  },

  row: {
    border: "1px solid gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontSize: "12px",
  },
  border: {
    border: "2px solid gray",
  },
  col: {
    flex: 1,
    justifyContent: "center",
  },
  col1: {
    flex: 0.5,
    justifyContent: "center",
    fontSize: "10px",
  },

  col2: {
    flex: 0.8,
    justifyContent: "center",
    fontSize: "10px",
  },
  header: {
    backgroundColor: "black",
    fontWeight: "bold",
    color: "white",
  },
  header1: {
    backgroundColor: "black",
    fontSize: "10px",
    color: "white",
  },
  heading: {
    fontWeight: "bold",
    marginBottom: 5,
    color: "black",
    fontSize: "20px",
  },
  heading1: {
    fontWeight: "bold",
    marginTop: 20,
    color: "black",
    fontSize: "20px",
  },
});

function PDFTable({ data, calculatorInput }) {
  return (
    <Document>
      <Page style={styles.container} size={[700]}>
        {/*Income Table*/}
        <View style={styles.userValueSection}>
          <View style={styles.contentWrapper}>
            <Text style={styles.topHeader}>
              Calculations Made by: https://rentalpropertycalculator.club/
            </Text>
            {/* <Image src={logo} style={styles.image} /> */}

            <Text style={styles.title}>CALCULATOR INPUT</Text>
            <Text style={styles.heading}>Income Form</Text>
            <View style={styles.border}>
              <View style={[styles.row, styles.header]}>
                <View style={[styles.col, styles.header]}>
                  <Text>Income Source</Text>
                </View>
                <View style={[styles.col, styles.header]}>
                  <Text>Amount</Text>
                </View>
                <View style={[styles.col, styles.header]}>
                  <Text>Annual Increase</Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Monthly Rent</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {calculatorInput.calculator_input.monthly_rent?.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {
                      calculatorInput.calculator_input
                        .monthly_rent_annual_increase
                    }
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Other Monthly Income</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {calculatorInput.calculator_input.other_monthly_income.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {
                      calculatorInput.calculator_input
                        .other_monthly_income_annual_increase
                    }
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Vacancy Rate</Text>
                </View>
                <View style={styles.col}>
                  <Text>{calculatorInput.calculator_input.vacancy_rate}%</Text>
                </View>
                <View style={styles.col}>
                  <Text></Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Management Fee</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {calculatorInput.calculator_input.management_fee}%
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text></Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.contentWrapper}>
            <Text style={styles.heading}>Recurring Operating Expenses</Text>
            <View style={styles.border}>
              <View style={[styles.row, styles.header]}>
                <View style={[styles.col, styles.header]}>
                  <Text>Expense Category</Text>
                </View>
                <View style={[styles.col, styles.header]}>
                  <Text>Annual</Text>
                </View>
                <View style={[styles.col, styles.header]}>
                  <Text>Annual Increase</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Property Tax</Text>
                </View>
                <View style={styles.col}>
                  <Text>${calculatorInput.calculator_input.property_tax}</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {
                      calculatorInput.calculator_input
                        .property_tax_annual_increase
                    }
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Total Insurance</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    ${calculatorInput.calculator_input.total_insurance}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {
                      calculatorInput.calculator_input
                        .total_insurance_annual_increase
                    }
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>HOA Fee</Text>
                </View>
                <View style={styles.col}>
                  <Text>${calculatorInput.calculator_input.hoa_fee}</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {calculatorInput.calculator_input.hoa_fee_annual_increase}%
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Maintenance</Text>
                </View>
                <View style={styles.col}>
                  <Text>${calculatorInput.calculator_input.maintenance}</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {
                      calculatorInput.calculator_input
                        .maintenance_annual_increase
                    }
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Other Costs</Text>
                </View>
                <View style={styles.col}>
                  <Text>${calculatorInput.calculator_input.other_costs}</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {
                      calculatorInput.calculator_input
                        .other_costs_annual_increase
                    }
                    %
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.contentWrapper}>
            <Text style={styles.heading}>Purchase Form</Text>
            <View style={styles.border}>
              <View style={[styles.row, styles.header]}>
                <View style={[styles.col, styles.header]}>
                  <Text>Purchase Form Values</Text>
                </View>
              </View>
              <View style={styles.row}>
                <Text style={styles.col}>Purchase Price</Text>
                <Text style={styles.col}>
                  ${calculatorInput.calculator_input.purchase_price}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.col}>Loan use</Text>
                {calculatorInput.calculator_input.is_using_loan ? (
                  <Text style={styles.col}>Yes</Text>
                ) : (
                  <Text style={styles.col}>No</Text>
                )}
              </View>
              {calculatorInput.calculator_input.is_using_loan === true && (
                <>
                  <View style={styles.row}>
                    <Text style={styles.col}>Down Payment</Text>
                    <Text style={styles.col}>
                      {calculatorInput.calculator_input.down_payment || ""} %
                    </Text>
                  </View>

                  <View style={styles.row}>
                    <Text style={styles.col}>Interest Rate</Text>
                    <Text style={styles.col}>
                      {calculatorInput.calculator_input.interest_rate || ""}%
                    </Text>
                  </View>

                  <View style={styles.row}>
                    <Text style={styles.col}>Loan Term</Text>
                    <Text style={styles.col}>
                      {calculatorInput.calculator_input.loan_term_in_years ||
                        ""}{" "}
                      Years
                    </Text>
                  </View>
                </>
              )}
              <View style={styles.row}>
                <Text style={styles.col}>Closing Cost</Text>
                <Text style={styles.col}>
                  ${calculatorInput.calculator_input.closing_cost}
                </Text>
              </View>
              {/* <View style={styles.row}>
                <Text style={styles.col}>Need Repairs?</Text>
                {calculatorInput.calculator_input.is_need_repairs ? (
                  <Text style={styles.col}>Yes</Text>
                ) : (
                  <Text style={styles.col}>No</Text>
                )}
              </View> */}
              {/* {calculatorInput.calculator_input.is_need_repairs === true && (
                <>
                  <View style={styles.row}>
                    <Text style={styles.col}>Repair Cost</Text>
                    <Text style={styles.col}>
                      ${calculatorInput.calculator_input.repair_cost}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.col}>Value after Repairs</Text>
                    <Text style={styles.col}>
                      ${calculatorInput.calculator_input.value_after_repair}
                    </Text>
                  </View>
                </>
              )} */}
            </View>
          </View>
          <View style={styles.contentWrapper}>
            <Text style={styles.heading}>Sell Form</Text>
            <View style={styles.border}>
              <View style={[styles.row, styles.header]}>
                <View style={[styles.col, styles.header]}>
                  <Text>Sell Form Values</Text>
                </View>
              </View>
              <View style={styles.row}>
                <Text style={styles.col}>Do You Know the Sell Price?</Text>
                {calculatorInput.calculator_input.is_know_sell_price ? (
                  <Text style={styles.col}>Yes</Text>
                ) : (
                  <Text style={styles.col}>No</Text>
                )}
              </View>

              <View style={styles.row}>
                <Text style={styles.col}>Value Appreciation</Text>
                <Text style={styles.col}>
                  {calculatorInput.calculator_input.value_appreciation}% Per
                  Year
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.col}>Sell Price</Text>
                {calculatorInput.calculator_input.is_know_sell_price ? (
                  <Text style={styles.col}>
                    {calculatorInput.calculator_input.sell_price}
                  </Text>
                ) : (
                  <Text style={styles.col}>N/A</Text>
                )}
              </View>
              <View style={styles.row}>
                <Text style={styles.col}>Holding Length</Text>
                <Text style={styles.col}>
                  {calculatorInput.calculator_input.holding_length} Years
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.col}>Cost to Sell</Text>
                <Text style={styles.col}>
                  {calculatorInput.calculator_input.cost_to_sell}%
                </Text>
              </View>
            </View>
          </View>
          {/*_____________________Result Table____________________________*/}
          {/*First year income and expense*/}
          <View style={styles.contentWrapper}>
            <Text style={styles.title}>CALCULATED RESULTS</Text>
            <Text style={styles.heading1}>First Year Income and Expense</Text>
            <View style={styles.border}>
              <View style={[styles.row, styles.header]}>
                <View style={[styles.col, styles.header]}>
                  <Text> </Text>
                </View>
                <View style={[styles.col, styles.header]}>
                  <Text>Monthly </Text>
                </View>
                <View style={[styles.col, styles.header]}>
                  <Text>Annual</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Income:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.income_monthly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.income_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Mortgage Pay:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.mortgage_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.mortgage_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Vacancy:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.vacancy_rate_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.vacancy_rate_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Management Fee:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.management_fee_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.management_fee_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Property Tax:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.property_tax_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.property_tax_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Total Insurance:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.total_insurance_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.total_insurance_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Maintenance Cost:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.maintenance_cost_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.maintenance_cost_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Other Cost:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.other_cost_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.other_cost_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Cash Flow:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.cash_flow_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.cash_flow_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Net Operating Income (NOI):</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.net_operating_income_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    $
                    {data.first_year_calculation.net_operating_income_yearly.toLocaleString(
                      "en-US"
                    )}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Capitalization Rate:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.first_year_calculation.net_operating_income_monthly.toLocaleString(
                      "en-US"
                    )}{" "}
                  </Text>
                </View>
                {calculatorInput.is_using_loan ? (
                  <View style={styles.col}>
                    <Text>Down Payment:</Text>
                    <Text>
                      {(
                        (calculatorInput.purchase_price *
                          calculatorInput.down_payment) /
                        100
                      ).toLocaleString("en-US")}
                    </Text>
                  </View>
                ) : (
                  ""
                )}
              </View>
            </View>
          </View>
          {/*For the 20 Years Invested*/}
          <View style={styles.contentWrapper}>
            <Text style={styles.heading1}>For the 20 Years Invested</Text>
            <View style={styles.border}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Total Profit when Sold:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.years_calculation.list_of_years
                      .reduce((total, year) => total + year.cash_flow, 0)
                      .toLocaleString("en-US")}{" "}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Cash on Cash Return:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    {data.years_calculation.list_of_years
                      .reduce(
                        (total, year) => total + year.cash_on_cash_return,
                        0
                      )
                      .toLocaleString("en-US")}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Capitalization Rate:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    {data.years_calculation.capitalization_rate.toLocaleString(
                      "en-US"
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Total Rental Income:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.years_calculation.list_of_years
                      .reduce((total, year) => total + year.annual_income, 0)
                      .toLocaleString("en-US")}{" "}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>Total Expenses:</Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {" "}
                    $
                    {data.years_calculation.list_of_years
                      .reduce((total, year) => total + year.expense, 0)
                      .toLocaleString("en-US")}{" "}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {/*BreakDown Over Time*/}
          <View style={styles.contentWrapper}>
            <Text style={styles.heading1}>Break Down Over Time</Text>
            <View style={styles.border}>
              <View style={[styles.row, styles.header]}>
                <View style={[styles.col1, styles.header1]}>
                  <Text>Year</Text>
                </View>
                <View style={[styles.col1, styles.header1]}>
                  <Text>Annual Income</Text>
                </View>
                <View style={[styles.col1, styles.header1]}>
                  <Text>Mortgage</Text>
                </View>
                <View style={[styles.col1, styles.header1]}>
                  <Text>Expenses</Text>
                </View>
                <View style={[styles.col1, styles.header1]}>
                  <Text>Cash Flow</Text>
                </View>
                <View style={[styles.col2, styles.header1]}>
                  <Text>Cash on Cash Return</Text>
                </View>
                <View style={[styles.col2, styles.header1]}>
                  <Text>Equity Accumulated</Text>
                </View>
                <View style={[styles.col2, styles.header1]}>
                  <Text>If Sold at Year End</Text>
                  <Text>Cash to Receive</Text>
                </View>
              </View>
              {data.years_calculation.list_of_years.map((obj, i) => (
                <View style={styles.row} key={i}>
                  <View style={styles.col1}>
                    <Text>{obj.id}</Text>
                  </View>
                  <View style={styles.col1}>
                    <Text>${obj.annual_income.toLocaleString("en-US")}</Text>
                  </View>
                  <View style={styles.col1}>
                    <Text>${obj.mortgage.toLocaleString("en-US")}</Text>
                  </View>
                  <View style={styles.col1}>
                    <Text>${obj.expense.toLocaleString("en-US")}</Text>
                  </View>
                  <View style={styles.col1}>
                    <Text>${obj.cash_flow.toLocaleString("en-US")}</Text>
                  </View>
                  <View style={styles.col2}>
                    <Text>
                      {obj.cash_on_cash_return
                        .toFixed(2)
                        .toLocaleString("en-US")}
                      %
                    </Text>
                  </View>
                  <View style={styles.col2}>
                    <Text>
                      ${obj.equity_accumulated.toLocaleString("en-US")}
                    </Text>
                  </View>
                  <View style={styles.col2}>
                    <Text>${obj.cash_to_recieve.toLocaleString("en-US")}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PDFTable;
