import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./Routes/index";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { checkAuthenticated } from "./Redux/actions/auth.actions";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAuthenticated());
  });
  return (
    <div className="App">
      <Header />
      <main className="main">
        <Container>
          <AppRoutes />
        </Container>
      </main>
      <ToastContainer position="top-right" />
      <Footer />
    </div>
  );
}

export default App;
