// LOADING CONSTANTS
export const SET_LOADING = "SET_LOADING";
export const CLEAR_LOADING = "CLEAR_LOADING";

// ADMIN CONSTANTS
export const ADMIN_REGISTER_FAIL = "ADMIN_REGISTER_FAIL";
export const ADMIN_REGISTER_SUCCESS = "ADMIN_REGISTER_SUCCESS";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const LOAD_LOGS_SUCCESS = "LOAD_LOGS_SUCCESS";
export const LOAD_LOGS_FAIL = "LOAD_LOGS_FAIL";
export const LOAD_USERS_SUCCESS = "ADMIN_USERS_SUCCESS";
export const LOAD_USERS_FAIL = "ADMIN_USERS_FAIL";


// MESSAGE/ERROR CONSTANTS
export const GET_MSG = "GET_MSG";
export const GET_ERROR = "GET_ERROR";
export const CLEAR_MSG = "CLEAR_MSG";

export const TOKEN_EXPIRE = "TOKEN_EXPIRE";

// AUTH CONSTANTS
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const PROFILE_FAIL = "PROFILE_FAIL";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const AUTHENTICATE_FAIL = "AUTHENTICATE_FAIL";
export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const USER_CALCULATIONS_FAIL = "USER_CALCULATIONS_FAIL";
export const USER_CALCULATIONS_SUCCESS = "USER_CALCULATIONS_SUCCESS";

export const CREATE_CALCULATION_FAIL = "CREATE_CALCULATION_FAIL";
export const CREATE_CALCULATION_SUCCESS = "CREATE_CALCULATION_SUCCESS";

export const GET_CALCULATION_DETAIL_FAIL = "GET_CALCULATION_DETAIL_FAIL";
export const GET_CALCULATION_DETAIL_SUCCESS = "GET_CALCULATION_DETAIL_SUCCESS";

export const CALCULATION_DELETE_FAIL = "CALCULATION_DELETE_FAIL";
export const CALCULATION_DELETE_SUCCESS = "CALCULATION_DELETE_SUCCESS";

export const MAKE_ADMIN_SUCCESS = "MAKE_ADMIN_SUCCESS"
export const MAKE_ADMIN_FAIL = "MAKE_ADMIN_FAIL"
