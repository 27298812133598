import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { reset_password } from "../Redux/actions/auth.actions";
import "./Form.css";

const ForgetPassowrd = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState();
  const [is_success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  if (error) {
    toast.error(error);
    setError(null);
  }

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(reset_password(formData, setError, setLoading, setSuccess));
  };
  if (is_success) {
    toast.success("Reset Password email has been sent successfully.");
    return <Navigate replace to="/" />;
  }
  return (
    <>
      <div className="row align-items-center justify-content-center login-page">
        <div className="col-xl-6 col-lg-6 col-sm-12 col-12 fxt-bg-color">
          <div className="login-wrapper">
            <div className="fxt-form">
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="form-group">
                  <div className="fxt-transformY-50 fxt-transition-delay-1">
                    <input
                      required
                      id="email"
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter your Email"
                      value={formData.email}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="fxt-transformY-50 fxt-transition-delay-4">
                    <button
                      type="submit"
                      className="fxt-btn-fill"
                      //   onClick={onSubmit}
                    >
                      {loading ? (
                        <ThreeDots
                          color="white"
                          height={10}
                          width={35}
                          radius={100}
                          ariaLabel="loading"
                          wrapperStyle={{ marginLeft: "35%" }}
                          wrapperClass
                        />
                      ) : (
                        ""
                      )}
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassowrd;
