import React from 'react'

function Loader() {
    return (
        <div id="page">
            <div id="container">
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="ring"></div>
                <div id="h3">loading</div>
            </div>
        </div>
    )
}

export default Loader