import React from "react";

const About = () => {
  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
    padding: "20px",
    textAlign: "center",
  };

  const sectionStyle = {
    maxWidth: "800px",
    margin: "0 auto 40px",
  };

  const teamMemberStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  };

  const memberImageStyle = {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "20px",
  };

  return (
    <div style={pageStyle}>
      <section style={sectionStyle}>
        <h1>About Us</h1>
        <p>
          Welcome to RentalPropertyCalculator.club, your destination for
          effortless property investment calculations. Our platform offers
          intuitive tools for evaluating potential property purchases, ensuring
          informed decisions.
        </p>
        <p>
          We're your partner in smart investing, providing a seamless experience
          and secure database to save your calculations. Track your progress,
          learn from your choices, and refine your strategies.
        </p>
        <p>
          Stay tuned as we expand our platform with exciting new features,
          designed to enhance your investment journey.
        </p>
        <p>
          Thank you for choosing RentalPropertyCalculator.club for your real
          estate ventures.
        </p>
      </section>
      <section style={sectionStyle}>
        <h2>Our Team</h2>
        <div style={teamMemberStyle}>
          <img src="/tarek.jpg" alt="Tarek" style={memberImageStyle} />
          <div>
            <h3>Tarek Alaaddin</h3>
            <p>Founder & CEO</p>
          </div>
        </div>
        {/* <div style={teamMemberStyle}>
          <img src="jane-smith.jpg" alt="Jane Smith" style={memberImageStyle} />
          <div>
            <h3>Jane Smith</h3>
            <p>Co-Founder & CTO</p>
          </div>
        </div> */}
      </section>
    </div>
  );
};

export default About;
