import {
  USER_CALCULATIONS_FAIL,
  USER_CALCULATIONS_SUCCESS,
  RESET_SUCCESS,
  RESET_FAIL,
} from "../constants";

const initialState = {
  ProfileData: [],
  loading: false,
  error: null,
  isreset: false,
};

const ProfileReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_CALCULATIONS_SUCCESS:
      return {
        ...state,
        ProfileData: payload,
        loading: false,
      };

    case USER_CALCULATIONS_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case RESET_FAIL:
      return {
        ...state,
        isReset: false,
      };

    case RESET_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default ProfileReducer;
