import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { signout } from "../../utils/auth";
import { getTokenIncludedConfig } from "./common";
import {
  LOGIN_FAIL,
  PROFILE_FAIL,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  PROFILE_SUCCESS,
  REGISTER_SUCCESS,
  AUTHENTICATE_FAIL,
  AUTHENTICATE_SUCCESS,
  RESET_FAIL,
  RESET_SUCCESS,
  MAKE_ADMIN_SUCCESS,
  MAKE_ADMIN_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
} from "../constants";

// REGIGSTER ACTION
export const register =
  (data, setLoadingStates, setError) => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await axios.post("users/signup", body);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      setLoadingStates(false);
    } catch (error) {
      toast.error(error.response.data.detail);
      setLoadingStates(false);
      dispatch({
        error: error.response,
        type: REGISTER_FAIL,
      });
    }
  };

// LOGIN ACTION
export const login =
  (data, setLoadingStates, setErrors) => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await axios.post("users/login", body);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      setLoadingStates(false);
    } catch (error) {
      setErrors(error.response.data);
      setLoadingStates(false);
      dispatch({
        error: error.response,
        type: LOGIN_FAIL,
      });
    }
  };

export const getProfile = () => async (dispatch) => {
  try {
    const res = await axios.get("users/me/", getTokenIncludedConfig());
    dispatch({
      type: PROFILE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_FAIL,
    });
  }
};

export const checkAuthenticated = () => async (dispatch) => {
  try {
    const res = await axios.get("users/verify", getTokenIncludedConfig());
    dispatch({
      type: AUTHENTICATE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTHENTICATE_FAIL,
    });
  }
};

export const makeAdmin =
  (is_admin, userId, loadingStates, setLoadingStates, index) =>
  async (dispatch) => {
    const newLoadingStates = [...loadingStates];
    newLoadingStates[index] = true;
    setLoadingStates(newLoadingStates);
    try {
      const res = await axios.patch(
        `admin/make_admin/${userId}/`,
        JSON.stringify({ is_admin }),
        getTokenIncludedConfig()
      );
      dispatch({
        type: MAKE_ADMIN_SUCCESS,
        payload: res.data,
      });
      toast.success(`Admin Privileges ${is_admin ? "Granted" : "Revoked"}`);
      newLoadingStates[index] = false;
      setLoadingStates(newLoadingStates);
    } catch (error) {
      toast.error("Network Error, Please try again.");
      newLoadingStates[index] = false;
      setLoadingStates(newLoadingStates);
      dispatch({
        type: MAKE_ADMIN_FAIL,
      });
    }
  };

// FORGOT PASSWORD ACTION
export const reset_password =
  (data, setError, setLoading, setSuccess) => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await axios.post(`users/password_reset`, body);
      dispatch({
        type: RESET_SUCCESS,
        payload: res.data,
      });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setLoading(false);
      setError(error.response.data.detail);
      dispatch({
        type: RESET_FAIL,
      });
    }
  };

export const reset_password_confirm =
  (data, setLoading, setErrors, setSuccess) => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await axios.post(
        "/users/change_password",
        body,
        getTokenIncludedConfig()
      );
      dispatch({
        type: RESET_SUCCESS,
        payload: res.data,
      });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setErrors("system respond is slow due to network please try again");
      setSuccess(false);
      setLoading(false);
      dispatch({
        error: error.response,
        type: RESET_FAIL,
      });
    }
  };

// LOGOUT ACTION
export const logout = (setRedirect) => (dispatch) => {
  setRedirect(true);
  signout();
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};
