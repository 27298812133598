import {
  LOAD_USERS_FAIL,
  MAKE_ADMIN_FAIL,
  LOAD_USERS_SUCCESS,
  MAKE_ADMIN_SUCCESS,
} from "../constants/index";
import { updateObjectById } from "../../utils/admin";

const initialState = {
  data: [],
  error: null,
};

const UserReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case LOAD_USERS_FAIL:
      return {
        ...state,
        error: payload,
      };
    case MAKE_ADMIN_SUCCESS:
      const updatedData = updateObjectById(state.data, payload);
      return {
        ...state,
        data: updatedData,
      };

    case MAKE_ADMIN_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default UserReducer;
