import React from "react";
import { Row, Col } from "react-bootstrap";

function SellFormValue({calculatedValue}) {
  return (
    <div className="user-value-section">
      <h3>Sell</h3>
      <div className="content-wrapper py-2">
        <Row className="p-0 m-0">
          <Col xs={7} className="p-0">
            <p>Do You Know the Sell Price?</p>
          </Col>
          <Col xs={5} className="p-0">
            <p>{calculatedValue.is_know_sell_price}</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={7} className="p-0">
            <p>Value Appreciation</p>
          </Col>
          <Col xs={5} className="p-0">
            <p>{calculatedValue.value_appreciation}% Per Year</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={7} className="p-0">
            <p>Sell Price</p>
          </Col>
          <Col xs={5} className="p-0">
            <p>${calculatedValue.sell_price}</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={7} className="p-0">
            <p>Holding Length</p>
          </Col>
          <Col xs={5} className="p-0">
            <p>{calculatedValue.holding_length} Years</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={7} className="p-0">
            <p>Cost to Sell</p>
          </Col>
          <Col xs={5} className="p-0">
            <p>{calculatedValue.cost_to_sell}%</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SellFormValue;
