import {
  CREATE_CALCULATION_FAIL,
  CALCULATION_DELETE_FAIL,
  CREATE_CALCULATION_SUCCESS,
  CALCULATION_DELETE_SUCCESS,
  GET_CALCULATION_DETAIL_FAIL,
  GET_CALCULATION_DETAIL_SUCCESS,
} from "../constants";

const initialState = {
  data: {},
  calculatedValue: {},
  loading: false,
  error: null,
};

const CalculatorReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CALCULATION_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case CALCULATION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_CALCULATION_FAIL:
    case GET_CALCULATION_DETAIL_FAIL:
    case CALCULATION_DELETE_FAIL:
      return {
        ...state,
        calculatedValue: {},
        error: payload,
        loading: false,
      };
    case GET_CALCULATION_DETAIL_SUCCESS:
      return {
        ...state,
        calculatedValue: payload,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default CalculatorReducer;
