import Home from "../Pages/Home";
import Login from "../Pages/Login";
import SignUp from "../Pages/SignUp";
import About from "../Pages/aboutus";
import Contact from "../Pages/contactus";
import Tutorial from "../Pages/tutorial";
import MyCalculations from "../components/MyAccount/MyCalculations";
import MyAccount from "../Pages/MyAccount";
import { Route, Routes } from "react-router-dom";
import Calculator from "../Pages/Calculator";
import ForgetPassowrd from "../Pages/ForgetPassowrd";
import ResetPasswordConfirm from "../Pages/reset_password_confirm";
import AdminAccount from "../Pages/Admin";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/myaccount" element={<MyAccount />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/tutorial" element={<Tutorial />} />
      <Route path="/calculations" element={<MyCalculations />} />
      <Route path="/calculator" element={<Calculator />} />
      <Route path="/forgetpassword" element={<ForgetPassowrd />} />
      <Route
        path="/password/reset/confirm/"
        element={<ResetPasswordConfirm />}
      />
      <Route path="/admin" element={<AdminAccount />} />
    </Routes>
  );
}

export default AppRoutes;
