import { decodeJwt } from "../../utils/auth";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  TOKEN_EXPIRE,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAIL,
} from "../constants";


const initialState = {
  access: null,
  loading: false,
  isAuthenticated: false,
  isAdmin: false,
  user: null,
};

const AuthReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
    case AUTHENTICATE_SUCCESS:
      localStorage.setItem("access", payload.access_token);
      const isAdmin = decodeJwt(payload.access_token);
      return {
        ...state,
        isAuthenticated: true,
        isAdmin,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
      };

    case LOGIN_FAIL:
    case PROFILE_FAIL:
    case REGISTER_FAIL:
    case AUTHENTICATE_FAIL:
      localStorage.removeItem("access");
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        user: null,
        access: null,
      };

        case LOGOUT_SUCCESS:
          const Access =localStorage.removeItem("access");
          const User= localStorage.removeItem("user");
          return {
            ...state,
            isAuthenticated: false,
            isAdmin: false,
            user: User,
            access: Access 
          };

    case TOKEN_EXPIRE:
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        user: null,
        access: null,
      };

    default:
      return state;
  }
};

export default AuthReducer;