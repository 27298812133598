import React from "react";
import { Row, Col } from "react-bootstrap";

function PurchaseFormValue({calculatedValue}) {
  return (
    <div className="user-value-section">
      <h3>PurchaseFormValue</h3>
      <div className="content-wrapper py-2">
        <Row className="p-0 m-0">
          <Col xs={7} className="p-0">
            <p>Purchase Price</p>
          </Col>
          <Col xs={5} className="p-0">
            <p>${calculatedValue.purchase_price}</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={7} className="p-0">
            <p>Loan use</p>
          </Col>
          <Col xs={5} className="p-0">
            <p>{calculatedValue.is_using_loan}</p>
          </Col>
        </Row>
        {calculatedValue.is_using_loan === true && (
          <>
            <Row className="p-0 m-0">
              <Col xs={7} className="p-0">
                <p>Down Payment</p>
              </Col>
              <Col xs={5} className="p-0">
                <p>{calculatedValue.down_payment} %</p>
              </Col>
            </Row>
            <Row className="p-0 m-0">
              <Col xs={7} className="p-0">
                <p>Interest Rate</p>
              </Col>
              <Col xs={5} className="p-0">
                <p>{calculatedValue.interest_rate}%</p>
              </Col>
            </Row>
            <Row className="p-0 m-0">
              <Col xs={7} className="p-0">
                <p>Loan Term</p>
              </Col>
              <Col xs={5} className="p-0">
                <p>{calculatedValue.loan_term_in_years} Years</p>
              </Col>
            </Row>
          </>
         ) }
        <Row className="p-0 m-0">
          <Col xs={7} className="p-0">
            <p>Closing Cost</p>
          </Col>
          <Col xs={5} className="p-0">
            <p>${calculatedValue.closing_cost}</p>
          </Col>
        </Row>
        {/* <Row className="p-0 m-0">
          <Col xs={7} className="p-0">
            <p>Need Repairs?</p>
          </Col>
          <Col xs={5} className="p-0">
            <p>{calculatedValue.is_need_repairs}</p>
          </Col>
        </Row> */}
        {calculatedValue.is_need_repairs === true && (
          <>
            <Row className="p-0 m-0">
              <Col xs={7} className="p-0">
                <p>Repair Cost</p>
              </Col>
              <Col xs={5} className="p-0">
                <p>${calculatedValue.repair_cost}</p>
              </Col>
            </Row>
            <Row className="p-0 m-0">
              <Col xs={7} className="p-0">
                <p>Value after Repairs</p>
              </Col>
              <Col xs={5} className="p-0">
                <p>${calculatedValue.value_after_repair}</p>
              </Col>
            </Row>
          </>
        ) }
      </div>
    </div>
  );
}

export default PurchaseFormValue;
