import React from "react";
import { Row, Col } from "react-bootstrap";

function IncomeFormValue({calculatedValue}) {
  return (
    <div className="user-value-section">
      <h3>Income</h3>
      <div className="content-wrapper">
        <Row className="p-0 m-0 header">
          <Col xs={6} className="p-0"></Col>
          <Col xs={3} className="p-0"></Col>
          <Col xs={3} className="p-0">
            <p>Annual Increase</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={6} className="p-0">
            <p>Monthly Rent</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>${calculatedValue?.monthly_rent}</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>{calculatedValue?.monthly_rent_annual_increase}%</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={6} className="p-0">
            <p>Other Monthly Income</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>${calculatedValue?.other_monthly_income}</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>
              {calculatedValue?.other_monthly_income_annual_increase}%
            </p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={6} className="p-0">
            <p>Vacancy Rate</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>{calculatedValue?.vacancy_rate}%</p>
          </Col>
          <Col xs={3} className="p-0">
            <p></p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={6} className="p-0">
            <p>Management Fee</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>{calculatedValue?.management_fee}%</p>
          </Col>
          <Col xs={3} className="p-0">
            <p></p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default IncomeFormValue;
