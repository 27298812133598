import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import { GET_CALCULATION_DETAIL_FAIL } from "../../Redux/constants";
import moment from "moment/moment";
import { getUserCalculations } from "../../Redux/actions/profile.actions";
import ViewCalculationModal from "../Modals/ViewCalculations";
import { useNavigate } from "react-router-dom";
import {
  deleteCalculation,
  getCalculatedValuesByID,
  getCalculatedCalculation,
} from "../../Redux/actions/calculator.actions";
import { toast } from "react-toastify";
import GenericTable from "./GenericTable";
import { pdf } from "@react-pdf/renderer";
import Table from "./Table";

export default function MyCalculations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const { ProfileData } = useSelector((state) => state.profile);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const { calculatedValue, data } = useSelector((state) => state.calculator);
  const [selectedCalculatotionID, setSelectedCalculatotionID] = useState(null);

  useEffect(() => {
    if (data && downloadClicked) {
      handleDownloadClick();
      setDownloadClicked(false);
    }
  }, [data, downloadClicked]);

  useEffect(() => {
    dispatch(getUserCalculations(selectedCalculatotionID));
  }, []);

  const handleDelete = (flag) => {
    setSelectedCalculatotionID(null);
    if (flag) {
      toast.success("Selected calculation has been deleted.");
    } else {
      toast.error(
        "Something bad happened. Please select the calculation and retry to delete."
      );
    }
  };

  const columns = [
    "Date",
    "Property Name",
    "Calculator Inputs",
    "Edit",
    "View",
    "Status",
    "Delete",
  ];

  async function handleDownloadClick() {
    const pdfBlob = await pdf(
      <Table data={data} calculatorInput={calculatedValue} />
    ).toBlob();
    const downloadLink = document.createElement("a");
    downloadLink.download = `${data.property_detail.name}_${data.property_detail.calculator_input.title}.pdf`;
    downloadLink.href = URL.createObjectURL(pdfBlob);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const tableData = ProfileData.map((obj) => {
    const calculatorInputs = obj.calculator_inputs || [];

    return {
      Date: moment(obj.created_at).format("YYYY-MM-DD"),
      "Property Name": obj.name,
      "Calculator Inputs": (
        <Autocomplete
          options={calculatorInputs}
          getOptionLabel={(option) => option.title}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Calculation Title"
              variant="outlined"
              style={{ fontSize: "2px" }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {option.id}: {option.title}
            </li>
          )}
          filterOptions={(options, state) => {
            const inputValue = state.inputValue.toLowerCase();
            return options.filter((option) =>
              option.title.toLowerCase().includes(inputValue)
            );
          }}
          onChange={(event, value) => {
            const selectedInputId = value?.id;
            setSelectedCalculatotionID(selectedInputId);
          }}
        />
      ),
      Edit: (
        <Button
          className="badge rounded-pill bg-black"
          variant="contained"
          color="secondary"
          onClick={() => {
            if (!selectedCalculatotionID) {
              toast.info("Please select a calculation before Editing");
              return;
            }
            navigate(`/calculator`);
            dispatch(getCalculatedValuesByID(selectedCalculatotionID));
          }}
        >
          EDIT
        </Button>
      ),
      View: (
        <Button
          className="badge rounded-pill bg-black"
          variant="contained"
          color="secondary"
          onClick={() => {
            if (!selectedCalculatotionID) {
              toast.info("Please select a calculation tittle");
              return;
            }
            setModalShow(true);
          }}
        >
          View
        </Button>
      ),
      Status: (
        <Button
          className="badge rounded-pill bg-black"
          variant="contained"
          color="secondary"
          onClick={() => {
            if (!selectedCalculatotionID) {
              toast.info("Please select a calculation before deleting.");
              return;
            }
            dispatch(
              getCalculatedCalculation(
                selectedCalculatotionID,
                setDownloadClicked
              )
            );
          }}
        >
          Get PDF
        </Button>
      ),
      Delete: (
        <Button
          className="badge rounded-pill bg-black"
          variant="contained"
          color="secondary"
          onClick={() => {
            if (!selectedCalculatotionID) {
              toast.info("Please select a calculation before deleting.");
              return;
            }
            dispatch(deleteCalculation(selectedCalculatotionID, handleDelete));
          }}
        >
          Delete
        </Button>
      ),
    };
  });
  return (
    <div>
      <h1 className="pt-3">
        <div className="d-flex justify-content-center my-3">
          <span>My Calculations</span>
        </div>
      </h1>
      <GenericTable columns={columns} data={tableData} />

      <ViewCalculationModal
        show={modalShow}
        calculationid={selectedCalculatotionID}
        onHide={() => {
          dispatch({
            type: GET_CALCULATION_DETAIL_FAIL,
          });

          setModalShow(false);
        }}
      />
    </div>
  );
}
