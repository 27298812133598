import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { getTokenIncludedConfig } from "./common";
import { getUserCalculations } from "./profile.actions";

import {
  CREATE_CALCULATION_FAIL,
  CALCULATION_DELETE_FAIL,
  CALCULATION_DELETE_SUCCESS,
  CREATE_CALCULATION_SUCCESS,
  GET_CALCULATION_DETAIL_FAIL,
  GET_CALCULATION_DETAIL_SUCCESS,
} from "../constants";

export const createCalculation = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(
      "calculator/create",
      body,
      getTokenIncludedConfig()
    );
    dispatch({
      type: CREATE_CALCULATION_SUCCESS,
      payload: res.data,
    });
    toast.success("Calculation successful.");
  } catch (error) {
    if (error.response) {
      console.error(error.response.data);
    }
    toast.error(
      "Something went wrong. Please check entered values and try again."
    );
    dispatch({
      type: CREATE_CALCULATION_FAIL,
    });
  }
};

export const getCalculatedCalculation =
  (id, setDownloadClicked = null) =>
  async (dispatch) => {
    try {
      const res = await axios.get(`users/calculated_data/${id}`);
      await dispatch({
        type: GET_CALCULATION_DETAIL_SUCCESS,
        payload: res.data.property_detail,
      });
      await dispatch({
        type: CREATE_CALCULATION_SUCCESS,
        payload: res.data,
      });
      if (setDownloadClicked) {
        setDownloadClicked(true);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Something went wrong. Please try again.");
      dispatch({
        type: CREATE_CALCULATION_FAIL,
      });
    }
  };

export const getCalculatedValuesByID = (ID) => async (dispatch) => {
  try {
    const res = await axios.get(
      `users/calculations/${ID}`,
      getTokenIncludedConfig()
    );
    dispatch({
      type: GET_CALCULATION_DETAIL_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CALCULATION_DETAIL_FAIL,
    });
  }
};

export const deleteCalculation = (ID, handleDelete) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `calculator/delete/${ID}`,
      getTokenIncludedConfig()
    );
    dispatch({
      type: CALCULATION_DELETE_SUCCESS,
      payload: res.data,
    });
    dispatch(getUserCalculations());
    handleDelete(true);
  } catch (error) {
    dispatch(getUserCalculations());
    handleDelete(false);
    dispatch({
      type: CALCULATION_DELETE_FAIL,
    });
  }
};
