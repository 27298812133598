import React, { useState } from "react";
import "./style.scss";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { IoIosMenu, IoMdClose } from "react-icons/io";

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  const closeMobileMenu = () => {
    setIsNavOpen(false);
  };

  return (
    <>
      <header className="header ">
        <Container>
          <nav className="navbar">
            <Link to="/">
              <img src="/logo.png" alt="Logo" className="logo" />
            </Link>
            <IoIosMenu className="toggler" onClick={() => setIsNavOpen(true)} />
            <div className="navbar-link-wrapper">
              <NavLink to="/" onClick={closeMobileMenu}>
                HOME
              </NavLink>
              <NavLink to="/about" onClick={closeMobileMenu}>
                ABOUT US
              </NavLink>
              <NavLink to="/contact" onClick={closeMobileMenu}>
                CONTACT US
              </NavLink>
              <NavLink to="/tutorial" onClick={closeMobileMenu}>
                TUTORIAL
              </NavLink>
              <NavLink to="/calculator" onClick={closeMobileMenu}>
                CALCULATOR
              </NavLink>

              {isAuthenticated ? (
                <>
                  <NavLink to="/calculations" onClick={closeMobileMenu}>
                    My CALCULATIONS
                  </NavLink>
                  <NavLink to="/myaccount" onClick={closeMobileMenu}>
                    My ACCOUNT
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink to="/login" onClick={closeMobileMenu}>
                    LOGIN
                  </NavLink>
                  <NavLink to="/signup" onClick={closeMobileMenu}>
                    REGISTER
                  </NavLink>
                </>
              )}
 
              {isAdmin && (
                <>
                  <NavLink to="/admin" onClick={closeMobileMenu}>
                    ADMIN
                  </NavLink>
                </>
              )}
            </div>
          </nav>
        </Container>
      </header>
      <div
        className={`navbar-link-wrapper-mobile container ${
          isNavOpen ? "show" : ""
        }`}
        style={{ color: "black" }}
      >
        <IoMdClose onClick={closeMobileMenu} aria-label="Close Menu" />
        <NavLink to="/" onClick={closeMobileMenu}>
          Home
        </NavLink>
        <NavLink to="/about" onClick={closeMobileMenu}>
          About us
        </NavLink>
        <NavLink to="/contact" onClick={closeMobileMenu}>
          Contact us
        </NavLink>
        <NavLink to="/tutorial" onClick={closeMobileMenu}>
          Tutorial
        </NavLink>

        {isAuthenticated ? (
          <>
            <NavLink to="/calculations" onClick={closeMobileMenu}>
              My Calculations
            </NavLink>
            <NavLink to="/myaccount" onClick={closeMobileMenu}>
              My Account
            </NavLink>
            {isAdmin && (
              <NavLink to="/admin" onClick={closeMobileMenu}>
                Admin
              </NavLink>
            )}
          </>
        ) : (
          <>
            <NavLink to="/login" onClick={closeMobileMenu}>
              LOGIN
            </NavLink>
            <br />
            <NavLink to="/signup" onClick={closeMobileMenu}>
              REGISTER
            </NavLink>
          </>
        )}
      </div>
    </>
  );
}

export default Header;
