import React from "react";
import { Row, Col } from "react-bootstrap";

function ExpensesFormValue({ calculatedValue }) {
  return (
    <div className="user-value-section">
      <h3>Recurring Operating Expenses</h3>
      <div className="content-wrapper">
        <Row className="p-0 m-0 header">
          <Col xs={6} className="p-0"></Col>
          <Col xs={3} className="p-0">
            <p>Annual</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>Annual Increase</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={6} className="p-0">
            <p>Property Tax</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>${calculatedValue?.property_tax}</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>{calculatedValue?.property_tax_annual_increase}%</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={6} className="p-0">
            <p>Total Insurance </p>
          </Col>
          <Col xs={3} className="p-0">
            <p>${calculatedValue?.total_insurance}</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>{calculatedValue?.total_insurance_annual_increase}%</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={6} className="p-0">
            <p>HOA Fee</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>${calculatedValue?.hoa_fee}</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>{calculatedValue?.hoa_fee_annual_increase}%</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={6} className="p-0">
            <p>Maintenance</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>${calculatedValue?.maintenance}</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>{calculatedValue?.maintenance_annual_increase}%</p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <Col xs={6} className="p-0">
            <p>Other Costs</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>${calculatedValue?.other_costs}</p>
          </Col>
          <Col xs={3} className="p-0">
            <p>{calculatedValue?.other_costs_annual_increase}%</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ExpensesFormValue;
