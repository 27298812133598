import React from "react";
import "./style.css";


function Footer() {
  return (
    <footer>
      <div className="container">
        <footer className="py-3 my-4">
          <ul className="nav justify-content-center border-bottom pb-3 mb-3">
            <li className="nav-item"><a href="/" className="nav-link px-2 text-body-secondary">Home</a></li>
            <li className="nav-item"><a href="/about" className="nav-link px-2 text-body-secondary">About</a></li>
            <li className="nav-item"><a href="/contact" className="nav-link px-2 text-body-secondary">Contact</a></li>
            <li className="nav-item"><a href="/tutorial" className="nav-link px-2 text-body-secondary">Tutorial</a></li>
          </ul>
          <p className="text-center text-body-secondary">© 2023 RentalPropertyCalculator</p>
        </footer>
      </div>
    </footer>
  );
}

export default Footer;
