import React, { useEffect , useState} from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import PurchaseFormValue from "./UserValues/PurchaseFormValue";
import IncomeFormValue from "./UserValues/IncomeFormValue";
import ExpensesFormValue from "./UserValues/ExpensesFormValue";
import SellFormValue from "./UserValues/SellFormValue";
import { getCalculatedValuesByID } from "../../../Redux/actions/calculator.actions";

function ViewCalculationModal(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { calculatedValue } = useSelector((state) => state.calculator);

  useEffect(() => {
    if (props.show) {
      setLoading(true);
      dispatch(getCalculatedValuesByID(props.calculationid))
        .then(  setTimeout(() => {
          setLoading(false);
        }, 700))
    }  
    return () => {
      setLoading(false);
    };
  }, [dispatch, props.show, props.calculationid]);
  
  


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Calculations
        </Modal.Title>
      </Modal.Header>
      {Object.entries(calculatedValue).length !== 0 && (
             
        <Modal.Body>
          {loading ? (<div className="d-flex justify-content-center">
             <div className="spinner-border" role="status">
               <span className="visually-hidden">Loading...</span>
             </div>
           </div>) : (
          <form className="calculator">
            <Row>
              <Col xs={12} sm={12} lg={12} className="order-2 order-sm-1">
                <Row className="p-0">
                  <Col xs={12} sm={6} lg={6}>
                    <PurchaseFormValue
                      calculatedValue={calculatedValue.calculator_input}
                    />
                  </Col>
                  <Col xs={12} sm={6} lg={6}>
                    <IncomeFormValue
                      calculatedValue={calculatedValue.calculator_input}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} lg={12} className="order-3 order-sm-3">
                <Row className="p-0">
                  <Col xs={12} sm={6} lg={6}>
                    <ExpensesFormValue
                      calculatedValue={calculatedValue.calculator_input}
                    />
                  </Col>
                  <Col xs={12} sm={6} lg={6}>
                    <SellFormValue
                      calculatedValue={calculatedValue.calculator_input}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
            )}
        </Modal.Body>
    
      )}
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewCalculationModal;
