import React from 'react'
import { Button } from '@mui/material'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CTA = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <>
      <div className="CTA">
        <div className='CTA_text'>
          <h1>Want To Analyze Your Properties?</h1>
          <span>
            We can help you estimate your property value
          </span>
        </div>
        {isAuthenticated ?     
        <div className='CTA_Buttons'>
          <Link to="/calculator"><Button variant="contained" style={{ "backgroundColor": "#87ceeb" }}>Get Your Free Trial</Button></Link>
        </div> :  
         <div className='CTA_Buttons'>
         <Link to="/signup"><Button variant="contained" style={{ "backgroundColor": "#87ceeb" }}>Get Your Free Trial</Button></Link>
       </div>
        }
      </div>
    </>
  )
}

export default CTA